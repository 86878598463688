import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./translations/en.json";
import translationRU from "./translations/ru.json";

const fallbackLng = ["en"];
const availableLanguages = ["en", "ru"];

const resources = {
	en: {
		translation: translationEN
	},
	ru: {
		translation: translationRU
	},
};

i18n
//	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng,
		detection: {
			checkWhitelist: true
		},
		debug: false,
		whitelist: availableLanguages,
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;

