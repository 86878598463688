import './App.css';
import React from 'react';
import MainPage from './pages/Main';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LanguageSelect from './components/LanguageSelect';
import { useTranslation } from "react-i18next";

function App() {
	const { t } = useTranslation();

	return (
	<Container className="App" maxWidth={false} disableGutters={true} height="100%">
		<LanguageSelect/>
		<Typography variant="h2" component="h1" gutterBottom style={{margin: "16px", color: "#92952e" }}>
			WhomHow
		</Typography>
		<Typography variant="body2" gutterBottom style={{ color: "#fff" }}>
			*** {t("moto")} ***
		</Typography>
	  	<Paper className="App-paper">
			<MainPage/>
		</Paper>
	</Container>
  );
}

export default App;
