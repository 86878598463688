import React, { useState } from 'react';
import PersonsList from '../components/PersonsList';
import CalcSheet from '../components/CalcSheet';

export default function () {
	
	const initPersons =  [];
/*		{ name: "Vasia", sum: 200, kk: 1001 },
		{ name: "Сидоров", sum: 1.53, kk: 1002 },
		{ name: "Петручио", sum: 0, kk: 1003 },
		{ name: "Petia", sum: 149, kk: 1004 },
	];  */

	const [persons, setPersons] = useState(initPersons);
	const [mode, setMode] = useState('list');
	// const [mode, setMode] = useState('calc');

	const onPersonsReady = (list) => {
		setPersons(list);
		setMode('calc');
	};

	return (
		<>
		{ mode === 'list' &&
			<PersonsList onReady={onPersonsReady} persons={persons} />
		}
		{ mode === 'calc' && persons.length > 0 && 
			<CalcSheet persons={persons} onReady={ () => setMode('list') } />
		}
		</>
	);
}
