import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
//import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
// import PersonRemove from '@mui/icons-material/PersonRemove';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
//import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from "react-i18next";

let personIdSeq = 1;

function Person(props) {
	const { t } = useTranslation();
	const data = props.data;
	const num = props.num;
	const handleOnChange = props.onChange;
	const removePerson = props.onRemove;

	return (
		<div>
				<TextField
					label={t("Name")}
					id={ "pname-" + num }
					defaultValue={data.name}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
							{ data.type === 's' && 
							 	<Tooltip title={t('ptt.sponsor')} placement="bottom">
									<AccessibilityNewIcon onClick={() => handleOnChange(num, 'type', 'p') } style={ { cursor: "pointer" } } />
								</Tooltip>
							}
							{ data.type === 'p' && 
							 	<Tooltip title={ t('ptt.participant') } placement="bottom">
								<AccountCircle onClick={() => handleOnChange(num, 'type', 's') } style={ { cursor: "pointer" } } />
								</Tooltip>
							}
							</InputAdornment>
						),
					}}
					onChange={(evt) => handleOnChange(num, 'name', evt.target.value) }
					error={data.name.length === 0 ? true: false }
					helperText={data.name.length === 0 ? t('set_name'): null}
				/>
				<TextField
					label={t('Amnt')}
					id={ "psum-" + num }
					defaultValue={data.sum}
					type="number"
					InputProps={{
						endAdornment: <InputAdornment position="end">{t('cur')}</InputAdornment>,
					}}
					onChange={(evt) => handleOnChange(num, 'sum', parseFloat(evt.target.value)) }
				/>
			<Fab color="secondary" style={{margin: "16px"}} aria-label="удалить участника" onClick={ () => removePerson(num) }>
				<RemoveIcon />
			</Fab>
		</div>
	);
}

export default function (props) {

	const { t } = useTranslation();

	let title = props.title ? props.title: t("Participants");
	const onReady = props.onReady;
	const initPersons = props.persons;
	const [persons, setPersons] = useState(initPersons);
	const addPerson = () => {
		const new_persons = [ ...persons];
		new_persons.push({type: "p", "name": "", "sum": "0", kk: personIdSeq++ });
		setPersons(new_persons);
	};

	const changePerson = (num, key, val) => {
		const new_persons = [ ...persons];
		new_persons[num-1][key] = val;
		setPersons(new_persons);
	}

	const removePerson = (num) => {
		const new_persons = [];
		if (persons.length > 1)
		{
			const fi = num - 1;
			for (let i = 0; i < persons.length; i++)
			{
				if (i !== fi)
						new_persons.push(persons[i]);
			}
		}
		setPersons(new_persons);
	};

	let Sum = 0;
	let Participants = 0;
	for (let i=0; i < persons.length; i++)
	{
		Sum += persons[i].sum;
		if (persons[i].type === 'p') Participants++;
	}

	return (
		<Box
			component="form"
			sx={{
				'& .MuiTextField-root': { m: 2, width: '25ch' },
			}}
			noValidate
			autoComplete="off"
		>
			<Typography variant="h4" component="h4" gutterBottom>
				{ title }
        	</Typography>
			{ persons.length === 0 && 
				<Alert severity="info" align="left">
					Представьте, решили вы с друзьями выпить.
					<br/>
					Решили, что Вася купит пиво, Петя закуски да еще пригласили
					Семёна и Вольдемара.
					<br/>
					Кто сколько кому должен? Здесь вы сможете решить эту
					проблему быстро. Добавляйте участников, укажите кто
					сколько потратил. Нажмите кнопку расчет!
				</Alert>
			}
			{	persons.map((item, index) => 
					<Person
					 	key={ "pp-" + item.kk }
						num={index+1}
						data={item}
						onChange={changePerson}
						onRemove={removePerson}
					/>
				)
			}
			<div>
				<Button variant="contained" onClick={addPerson} style={{margin: "16px"}} >{t("Button.Add")}</Button>
				{ persons.length > 1 && Participants > 0 && Sum > 0 && 
				<Button variant="contained" onClick={() => onReady(persons)}  style={{margin: "16px"}}>{t("Button.Calc")}</Button>
				}
			</div>
		</Box>
	)
}
