import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";

const languageMap = [
  { lang: 'en-En', label: "EN", active: true },
  { lang: 'ru-RU', label: "RU", active: false },
];

export default function () {

	const { t } = useTranslation();
	const selected = localStorage.getItem("i18nextLng") || "en-En";
	let label = languageMap[0].label;
	for (let i = 0; i < languageMap.length; i++)
	{
		if (languageMap[i].lang === selected) 
		{
			label = languageMap[i].label;
			break;
		}
	}

	const [menuAnchor, setMenuAnchor] = React.useState(null);

	return (
    <div className="d-flex justify-content-end align-items-center language-select">
      <Button onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
        {label}
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div>
          <List>
            <ListSubheader>{t("select_language")}</ListSubheader>
            {languageMap.map(item => (
              <ListItem
                button
                key={item.lang}
                onClick={() => {
                  i18next.changeLanguage(item.lang);
                  setMenuAnchor(null);
                }}
              >
                {item.label}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  )

}
