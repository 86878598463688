const initPerson = (p, id, avg_debt) => {
	p.sum = Math.floor(p.sum * 100);
	p.id = id;
	if (p.type === 's')
	{
		p.debt = p.__cur_debt = -1 * p.sum;
	}
	else
	{
		p.debt = p.__cur_debt = avg_debt - p.sum;
	}
	p.oppo = [];
};

const isDebtor = (p) => {
	return p.__cur_debt > 0 ? true: false;
};

const isCreditor = (p) => {
	return p.__cur_debt < 0 ? true: false;
};

const isContent = (p) => {
	return p.__cur_debt === 0 ? true: false;
};

const giveMoneyTo = (p1, p2, sum) => {
	p1.__cur_debt -= sum;
	p1.oppo.push({id: p2.id,  sum: sum });
	p2.__cur_debt += sum;
}

const handleEqualDebts = (people) => {

	let ndebts = 0;
	const plen = people.length;

	for (let i = 0; i < plen; i++)
	{
		const p1 = people[i];
		if (!isDebtor(p1)) continue;
		let done = false;
		for (let j = 0; j < plen; j++)
		{
			if (i === j) continue;
			const p2 = people[j];
			if (!isCreditor(p2)) continue;
			if (p1.__cur_debt === (-1 * p2.__cur_debt))
			{
				giveMoneyTo(p1, p2, -1 * p2.__cur_debt);
				done = true;
				break;
			}
		}
		if (done) break;
		ndebts++;
	}

	return ndebts;
}

const getSortedRefs = (data) => {
	const ds = [];
	const cs = [];

	const cmp = (A, B) => {
		if (A > B)  return -1;
		if (A < B)  return 1;
		return 0;
	};

	data.forEach((e) => {
		if (isDebtor(e)) {
			ds.push(e.id);
		} else if (isCreditor(e)) {
			cs.push(e.id);
		}
	});

	return [
		ds.sort((A, B) => cmp(data[A].__cur_debt, data[B].__cur_debt)),
		cs.sort((A, B) => cmp(data[B].__cur_debt, data[A].__cur_debt))
	];
};

const handleInclusiveDebts = (people) => {

	let n = 0
	while (n++ < 100) {
		let action = 0;
		const [ debtors, creditors ] = getSortedRefs(people); 
		for (let di = 0; di <  debtors.length; di++) 
		{
			const D = people[debtors[di]];
			for (let ci = 0; ci < creditors.length; ci++)
			{
				const C = people[creditors[ci]];
				if (!isCreditor(C)) continue;
				if (debt(D) <= debt(C) * -1)
				{
					giveMoneyTo(D, C, debt(D));
					action++;
					break;
				}
			}
		}
		if (action === 0)
			return [ debtors, creditors ];
	}

	return [null, null];
};

const handleFinalDebts = (people, debtors, creditors) => {
	for (let di = 0; di <  debtors.length; di++) 
	{
		const D = people[debtors[di]];
		for (let ci = 0; ci < creditors.length; ci++)
		{
			const C = people[creditors[ci]];
			if (!isCreditor(C)) continue;
			giveMoneyTo(D, C, -1 * debt(C));
			if (isContent(D)) break;
		}
	}
};

const debt = (person) => {
	return person.__cur_debt;
};

export const setTheLoan = (persons_in) => {
	let sum = 0;
	const people = [];
	for (let i = 0; i < persons_in.length; i++)
	{
		people.push({...persons_in[i]});
		sum += Math.floor(people[i].sum * 100);
	}
	const plen = people.length;
	let partisipants = 0;
	for (let i = 0; i < plen; i++)
	{
		if (people[i].type === 'p')
							partisipants++;
	}

	const perPerson = Math.floor(sum/partisipants);
	for (let i = 0; i < plen; i ++)
	{
		initPerson(people[i], i, perPerson);
	}
	let ndebts = handleEqualDebts(people);
	if (ndebts)
	{
		const [debtors, creditors] = handleInclusiveDebts(people);
		if (debtors.length > 0)
		{
			handleFinalDebts(people, debtors, creditors);
		}
	}

	return {
			Sum: sum,
			perPerson: perPerson,
			people: people
	};
};

