import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { setTheLoan } from './loan.js';

const money = (sum) => {
	if (sum === 0) return "0";

	let kop = Math.abs(sum) % 100;
	let rub = Math.floor(sum/100);

	let s = "" + rub;
	if (kop > 0)
	{
		s += "." + kop;
	}
	return s;

};

function PersonDebts(props) {
	const person = props.person;
	const all = props.persons;
	const oppo = person.oppo;

	return (
		<>
		{ oppo.map( (o, idx) => (
			<TableRow key={"oo-" + person.id + "-" + idx }>
				<TableCell>{ person.name }</TableCell>
				<TableCell>{ all[o.id].name }</TableCell>
				<TableCell align="right">{ money(o.sum) }</TableCell>
			</TableRow>
		))}
		</>
	)
}

function MoneyAction({sum}) {
	if (sum === 0)
	{
		return (<></>);
	}
	if (sum > 0)
	{
		return (<span style={{color: "RED"}}>отдать</span>);
	}
	return (<span style={{color: "GREEN" }}>получить</span>);
}

function MoneyToPeople({people}) {

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Участник</TableCell>
						<TableCell align="right">Потратил</TableCell>
						<TableCell>Должен</TableCell>
						<TableCell align="right">Сумму</TableCell>

					</TableRow>
				</TableHead>
				<TableBody>
				{ people.map((p, idx) => (
					<TableRow key={"mm-" + idx }>
						<TableCell>{ p.name }</TableCell>
						<TableCell align="right">{ money(p.sum) }</TableCell>
						<TableCell><MoneyAction sum={p.debt} /></TableCell>
						<TableCell align="right">{ money(Math.abs(p.debt)) }</TableCell>
					</TableRow>
				))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

function PeopleToPeople({people}) {

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Участник</TableCell>
						<TableCell>Должен</TableCell>
						<TableCell align="right">Отдать</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{ people.filter((p) => { return p.debt < 0 ? false: true;})
					.map((p, idx) => (
						<PersonDebts
							person={p}
							key={"pd-" + idx}
							persons={people}
						/>
				))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

function Itogo({data}) {

	return (
		<TableContainer component={Paper} style={{ marginTop: "30px", backgroundColor: "#c0ccdd" }}>
			<Table>
				<TableBody>
					<TableRow>
						<TableCell><strong>Общая сумма:</strong></TableCell>
						<TableCell>{ money(data.Sum) }</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><strong>Доля каждого:</strong></TableCell>
						<TableCell>{ money(data.perPerson) }</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default function ({persons, onReady}) {
	const data = setTheLoan(persons);
	const [whichTab, setTab] = useState(0);

	const handleChange = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<>
		<Typography variant="h4" component="h4" gutterBottom>
			Рассчет
		</Typography>
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={whichTab} onChange={handleChange} aria-label="basic tabs example">
					<Tab label="Должники" id="tab-0" aria-controls="tabpanel-0" />
					<Tab label="Распределение денег" id="tab-1" aria-controls="tabpanel-1"/>
				</Tabs>
			</Box>
			<div
				role="tabpanel"
				hidden={whichTab !== 0}
				id="tabpanel-0"
      			aria-labelledby="tab-0"
			>
				<PeopleToPeople people={data.people} />
			</div>
			<div
				role="tabpanel"
				hidden={whichTab !== 1}
				id="tabpanel-1"
      			aria-labelledby="tab-1"
			>
				<MoneyToPeople people={data.people} />
			</div>
		</Box>
		<Grid container>
			<Grid item xs={4} sm={8} />
			<Grid item xs={8} sm={4}><Itogo data={data}/></Grid>
		</Grid>
		<div>
			<Button variant="contained" onClick={onReady}  style={{margin: "16px"}}>Список участников</Button>
		</div>
		</>
	)
}
